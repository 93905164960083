import React, { Component } from 'react'
import styles from './Shell.module.css'
import Header from '../Header/Header'
import apolloMobile from '../../Assets/images/png/apolloMobile.png'
import apolloDesktop from '../../Assets/images/png/apolloDesktop.png'

/**
 * Shell component.
 * @returns {void} .
 */
class Shell extends Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.state = {
      apolloImage: ''
    }
  }

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.resize()
  }

  /**
   * Component Will Unmount
   * @return {void}
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize = () => {
    const image = window.innerWidth > 428 ? apolloDesktop : apolloMobile
    this.setState({ apolloImage: image })
  }

  /**
   * Render
   * @return {void}
   */
  render() {
    const { image, children } = this.props
    const { apolloImage } = this.state

    return (
      <>
        <Header />
        {image && (
          <div className={styles.ContainerApollo}>
            <img
              alt="apolo"
              src={apolloImage}
              className={styles.Apollo}
            />
          </div>
        )}
        <div className={styles.Container}>{children}</div>
      </>
    )
  }
}

export default Shell
