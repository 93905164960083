/**
 * Invoicing Back
 * @param {object} dataForm .
 * @param {function} success .
 * @param {function} error .
 * @return {void} .
 */
const sendBack = async (dataForm, success, error) => {
  const BasicAuthUsername = process.env.REACT_APP_AUTH_USERNAME
  const BasicAuthPassword = process.env.REACT_APP_AUTH_PASSWORD
  const auth = `${BasicAuthUsername}:${BasicAuthPassword}`
  const authEncoded = `Basic ${Buffer.from(auth).toString('base64')}`

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: authEncoded,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataForm)
  }

  try {
    const urlInvoicing = process.env.REACT_APP_INVOICING_URL

    const response = await fetch(urlInvoicing, requestOptions)
    const data = await response.json()

    if (response.ok) {
      success && success(data)
    } else {
      error && error(data)
    }
  } catch (err) {
    error && error(err)
  }
}

export default { sendBack }
