import React from 'react'
import styles from './Form.module.css'
import FormGroup from '../FormGroup/FormGroup'
import messages from './Form.messages'

/**
 * Form Component
 * @param {*} props - .
 * @param {boolean} errorTicket - true if error in ticket input
 * @param {boolean} errorSocialReasons - true if error in social reasons input
 * @param {boolean} errorRFC - true if error in rfc input
 * @returns {void} .
 */
const Form = (props) => {
  const {
    errors,
    onChangeValue,
    form
  } = props

  return (
    <div className={styles.FormContainer}>
      <FormGroup
        idInput="ticket"
        error={errors && errors.errorTicket}
        typeInput="text"
        textLabel={messages.ticket}
        defaultValue={form && form.ticket}
        onChange={(event) => onChangeValue('ticket', event.target.value)}
      />
      <FormGroup
        idInput="socialReasons"
        error={errors && errors.errorSocialReasons}
        typeInput="text"
        textLabel={messages.socialReasons}
        defaultValue={form && form.socialReasons}
        onChange={(event) => onChangeValue('socialReasons', event.target.value)}
      />
      <FormGroup
        idInput="rfc"
        error={errors && errors.errorRFC}
        typeInput="text"
        textLabel={messages.rfc}
        defaultValue={form && form.number}
        onChange={(event) => onChangeValue('rfc', event.target.value)}
      />
    </div>
  )
}

export default Form
