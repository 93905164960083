import React from 'react';

const description = ()=>{
  console.log(process.env)
  const phone = process.env.REACT_APP_WHATSAPP_MX
  return <div>Si necesitas ayuda escribenos por whatsapp al <a rel="noopener" target="_blank" href={`https://api.whatsapp.com/send?phone=52${phone}&text=Tengo%20problemas%20para%20generar%20mi%20factura`}>{phone}</a> o intenta de nuevo.</div>
}

const messages = {
  title: 'Parece que hubo un error',
  description: description(),
  tryAgain: 'INTENTAR DE NUEVO',
}

export default messages
