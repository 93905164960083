/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/jsx-handler-names */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, createRef } from 'react'
import arrow from '../../Assets/images/svg/arrow_down.svg'
import styles from './Dropdown.module.css'

/**
 * Dropdown Component
 * @returns {React.Component} Dropdown .
 */
class Dropdown extends Component {
  /**
   * Constructor.
   * @param {object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    const { defaultValue } = this.props
    this.state = {
      openDropDown: false,
      currentTopic: defaultValue || { id: '', text: '' }
    }
    this.refImg = createRef(null)
  }

  OpenDropDown = () => {
    const { openDropDown } = this.state
    const open = !openDropDown
    this.refImg.current.style.transform = `rotate(${open ? 0 : 180}deg)`
    this.setState({ openDropDown: open })
  }

  /**
   * Change value selector
   * @param {object} item .
   * @param {object} title .
   * @returns {React.Component} Reference .
   */
  changeValue = (item) => {
    this.setState({ currentTopic: item })
    this.props.onChange(item.value)
  }

  /**
   * Render.
   * @param {string} id .
   * @returns {void} .
   */
  render() {
    const { openDropDown, currentTopic } = this.state
    const {
      items, error, classProps, focusDrop
    } = this.props
    let errorSpan = null
    if (error) {
      errorSpan = <span className={styles.ErrorMessage}>{error}</span>
    }
    const listItems = items && items.map(item => {
      const idText = item.value
      const name = item.value
      if (currentTopic.id !== item.id) {
        const itemText = `${item.value} - ${item.text}`
        return (
          <li
            key={item.id}
            id={idText}
            name={name}
            onClick={() => { this.changeValue(item) }}
            className={styles.Items}
            role="menuitem"
          >
            {itemText}
          </li>
        )
      }
      return null
    })
    const selector = (
      <div className={[styles.ThemesMobileContainer, classProps].join(' ')}>
        <div
          className={styles.TopicSelector}
          onClick={() => this.OpenDropDown()}
          role="button"
          tabIndex="0"
          onFocus={() => focusDrop(true)}
          onBlur={() => {
            this.setState({ openDropDown: false })
            focusDrop(false)
          }}
        >
          <div className={styles.TextTema}>
            {`${currentTopic.value} - ${currentTopic.text}`}
            <img
              ref={this.refImg}
              alt="flecha"
              src={arrow}
              style={{ transform: `rotate(${openDropDown ? 0 : 180}deg)` }}
            />
          </div>
          {openDropDown && (
            <ul className={styles.Scroll}>
              {listItems}
            </ul>
          )}
        </div>
      </div>
    )

    return (
      <div className={styles.DropdownContainer}>
        {selector}
        {errorSpan}
      </div>
    )
  }
}

export default Dropdown
