const messages = {
  title: 'Obtén tu factura moons',
  description: 'En moons queremos que sonrías siempre. Por eso, lanzamos nuestro sistema de facturación, haciéndolo rápido, sencillo, y accesible. A partir de ahora podrás generar todas tus facturas aquí, incluyendo tu pago por tratamiento de alienadores y home kit',
  subDesc: 'Si necesitas facturar alguno de tus pagos, ingresa tus datos fiscales y tu numero de ticket (lo puedes encontrar en tu comprobante de pago)',
  cfdi: 'Uso de CFDI',
  textButton: 'CONTINUAR',
  cfdiTypes: [{
    id: 0,
    value: 'G01',
    text: 'Adquisición de Mercancías'
  },
  {
    id: 1,
    value: 'G02',
    text: 'Devoluciones, Descuentos o Bonificaciones'
  },
  {
    id: 2,
    value: 'G03',
    text: 'Gastos en General'
  },
  {
    id: 3,
    value: 'I01',
    text: 'Construcciones'
  },
  {
    id: 4,
    value: 'I02',
    text: 'Mobiliario y Equipo de Oficina'
  },
  {
    id: 5,
    value: 'I03',
    text: 'Equipo de Transporte'
  },
  {
    id: 6,
    value: 'I04',
    text: 'Equipo de Cómputo y Accesorios'
  },
  {
    id: 7,
    value: 'I05',
    text: 'Herramientas'
  },
  {
    id: 8,
    value: 'I06',
    text: 'Comunicaciones Telefónicas'
  },
  {
    id: 9,
    value: 'I07',
    text: 'Comunicaciones Satelitales'
  },
  {
    id: 10,
    value: 'I08',
    text: 'Otra Maquinaria y Equipo'
  },
  {
    id: 11,
    value: 'D01',
    text: 'Honorarios Médicos, Dentales'
  },
  {
    id: 12,
    value: 'D02',
    text: 'Gastos Médicos por Incapacidad o Discapacidad'
  },
  {
    id: 13,
    value: 'D03',
    text: 'Gastos Funerales'
  },
  {
    id: 14,
    value: 'D04',
    text: 'Donativos'
  },
  {
    id: 15,
    value: 'D05',
    text: 'Créditos Hipotecarios'
  },
  {
    id: 16,
    value: 'D06',
    text: 'Aportaciones Voluntarias al SAR'
  },
  {
    id: 17,
    value: 'D07',
    text: 'Seguros de Gastos Médicos'
  },
  {
    id: 18,
    value: 'D08',
    text: 'Transportación Escolar'
  },
  {
    id: 19,
    value: 'D09',
    text: 'Planes de Pensiones'
  },
  {
    id: 20,
    value: 'D10',
    text: 'Colegiaturas'
  },
  {
    id: 21,
    value: 'P01',
    text: 'Por Definir'
  }]
}

export default messages
