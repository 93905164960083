import React from 'react'
import styles from './Error.module.css'
import messages from './Error.messages'

/**
 * Error Component
 * @param {Object} response - json to the response
 * @return {void}
 */
class Error extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  /**
   * @param {Event} event - onClick event
   * @return {void} .
   */
  handleClick = event => {
    event.preventDefault()
    window.location.reload()
  }

  /**
   * Response Detail
   * @param {Object} item .
   * @return {void} .
   */
  responseDetail = (item) => {
    let message = 'Error'
    if (item && typeof item.message === 'object') {
      message = item.message && item.message.message
    } else {
      message = item.message
    }
    return <li className={styles.ErrorMessage}>{message}</li>
  }

  /**
   * @returns {void}
   */
  render() {
    const position = 0
    window.scrollTo(position, position)

    const { error } = this.props

    return (
      <div className={styles.ErrorContainer}>
        <div className={styles.ErrorTitle}>{messages.title}</div>
        <div className={styles.Description}>{messages.description}</div>
        {this.responseDetail(error)}
        <div className={styles.FormSubmitButton}>
          <div
            role="button"
            tabIndex={0}
            className={styles.SubmitButton}
            onClick={(event) => this.handleClick(event)}
          >
            {messages.tryAgain}
          </div>
        </div>
      </div>
    )
  }
}

export default Error
