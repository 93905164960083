/* eslint-disable react/jsx-child-element-spacing */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable prefer-destructuring */
import React, { Component, createRef } from 'react'
import { withRouter } from 'react-router-dom'
import styles from './Invoice.module.css'
import messages from './Invoice.messages'
import Shell from '../Shell/Shell'
import Form from '../Form/Form'
import Dropdown from '../Dropdown/Dropdown'
import Modal from '../Modal/Modal'
import LoaderSection from '../LoaderSection/LoaderSection'
import Success from '../Success/Success'
import Error from '../Error/Error'
import InvoicingBack from '../../Api/InvoicingBack'

/**
 * Invoice component.
 * @returns {void} .
 */
class Invoice extends Component {
  /**
   * Constructor.
   * @param {Object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)

    const ticket = props.match.params.operationId
    this.cfdiDefault = messages.cfdiTypes.filter(item => item.value === 'G03')[0]

    this.state = {
      ticket: ticket || '',
      socialReasons: '',
      rfc: '',
      cfdi: this.cfdiDefault.value,
      errorTicket: false,
      errorSocialReasons: false,
      errorRFC: false,
      errorCFDI: '',
      confirm: false,
      loader: false,
      success: false,
      error: false,
      response: ''
    }

    this.refLabel = createRef(null)
  }

  /**
   * Change Form Data .
   * @param {String} key .
   * @param {String} value .
   * @returns {void} .
   */
  changeFormData = (key, value) => {
    const obj = []
    obj[key] = value
    this.setState(obj)
  }

  /**
   * Validate Form .
   * @returns {void} .
   */
  validateForm = () => {
    const {
      ticket, socialReasons, rfc
    } = this.state

    let errorTicket = false
    let errorRFC = false
    let errorSocialReasons = false
    const re = /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/
    if (!re.test(rfc.toString())) {
      errorRFC = 'RFC Inválida!'
    }
    if (!rfc) errorRFC = 'Campo Requerido'

    if (!ticket) errorTicket = 'Campo Requerido'

    if (!socialReasons) errorSocialReasons = 'Campo Requerido'

    this.setState({ errorRFC, errorTicket, errorSocialReasons })

    if (errorRFC || errorSocialReasons || errorTicket) {
      return false
    }
    return true
  }

  /**
   * Add Class
   * @param {Element} el .
   * @param {String} myClass .
   * @returns {void} .
   */
  addClass = (el, myClass) => {
    el.classList.add(myClass)
  }

  /**
   * Remove Class
   * @param {Element} el .
   * @param {String} myClass .
   * @returns {void} .
   */
  removeClass = (el, myClass) => {
    el.classList.remove(myClass)
  }

  /**
   * Focus Drop
   * @param {Element} value .
   * @returns {void} .
   */
  focusDrop = (value) => {
    if (value) {
      this.addClass(this.refLabel.current, styles.Blue)
    } else {
      this.removeClass(this.refLabel.current, styles.Blue)
    }
  }

  confirmInvoice = () => {
    if (this.validateForm()) {
      this.setState({ confirm: true })
    }
  }

  sendToBack = () => {
    const {
      cfdi, socialReasons, ticket, rfc
    } = this.state
    const data = {
      OperationId: ticket,
      RFC: rfc,
      Razon_Social: socialReasons,
      Uso_CFDI: cfdi,
      Update_Info: true
    }

    this.setState({ confirm: false, loader: true })
    InvoicingBack.sendBack(data, this.handleSuccess, this.handleError)
  }

  /**
   * Handle Success
   * @param {String} data .
   * @returns {void} .
   */
  handleSuccess = (data) => {
    this.setState({ success: true, loader: false, response: data })
  }

  /**
   * Handle Error
   * @param {String} error .
   * @returns {void} .
   */
  handleError = (error) => {
    this.setState({ error, loader: false })
  }

  /**
   * Render.
   * @returns {void} .
   */
  render() {
    const {
      ticket, socialReasons, rfc, errorTicket, cfdi,
      errorSocialReasons, errorRFC, errorCFDI, confirm, loader,
      success, error, response
    } = this.state

    const { selector } = this.props

    if (success) {
      return (
        <Shell image>
          <Success response={response} />
        </Shell>
      )
    }

    if (error) {
      return (
        <Shell image>
          <Error error={error} />
        </Shell>
      )
    }

    return (
      <Shell>
        {confirm && (
          <Modal
            {... {
              rfc, cfdi, socialReasons, ticket
            }}
            close={() => this.setState({ confirm: false })}
            confirm={() => this.sendToBack()}
          />
        )}
        <div className={styles.ContainerBilling}>
          <div className={styles.Title}>{messages.title}</div>
          <div className={styles.Description}>{messages.description}</div>
          <div className={styles.SubDesc}>{messages.subDesc}</div>
          {loader ? <LoaderSection />
            : (
              <div className={styles.FormContainer}>
                <Form
                  errors={{ errorTicket, errorSocialReasons, errorRFC }}
                  form={{ ticket, socialReasons, rfc }}
                  onChangeValue={(key, value) => this.changeFormData(key, value)}
                />
                <div className={styles.InvoiceText}>
                  <p>
                    Solo podemos generar facturas Gastos en  General, consulta nuestros&nbsp;
                    <a
                      href={process.env.REACT_APP_TC_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terminos y Condiciones
                    </a>
                  </p>
                </div>
                {selector && (
                  <>
                    <div
                      ref={this.refLabel}
                      className={styles.LabelDropDown}
                    >
                      {messages.cfdi}
                    </div>
                    <Dropdown
                      focusDrop={(value) => this.focusDrop(value)}
                      onChange={(value) => { this.setState({ cfdi: value }) }}
                      items={messages.cfdiTypes}
                      defaultValue={this.cfdiDefault}
                      error={errorCFDI}
                    />
                  </>
                )}
              </div>
            ) }
          {!loader && (
            <div className={styles.FormSubmitButton}>
              <div
                role="button"
                tabIndex={0}
                className={styles.SubmitButton}
                onClick={() => this.confirmInvoice()}
              >
                {messages.textButton}
              </div>
            </div>
          )}
        </div>
      </Shell>
    )
  }
}

export default withRouter(Invoice)
