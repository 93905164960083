import React from 'react'
import styles from './Modal.module.css'
import messages from './Modal.messages'

/**
 * Modal Component
 * @returns {React.Component} Modal .
 */
const Modal = ({
  rfc, cfdi, socialReasons, ticket, close, confirm
}) => (
  <div className={styles.ModalContainer}>
    <div className={styles.Wrapper}>
      <div className={styles.Title}>{messages.title}</div>
      <div className={styles.SubTitle}>{messages.subTitle}</div>
      <div className={[styles.Wave, styles.WaveTop].join(' ')} />
      <div className={styles.TicketContainer}>
        <div className={styles.Item}>
          <p className={styles.Key}>{messages.ticket}</p>
          <p className={styles.Value}>{ticket}</p>
        </div>
        <div className={styles.Item}>
          <p className={styles.Key}>{messages.socialReasons}</p>
          <p className={styles.Value}>{socialReasons}</p>
        </div>
        <div className={styles.Item}>
          <p className={styles.Key}>{messages.rfc}</p>
          <p className={styles.Value}>{rfc}</p>
        </div>
        <div className={styles.Item}>
          <p className={styles.Key}>{messages.cfdi}</p>
          <p className={styles.Value}>{cfdi}</p>
        </div>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={styles.SubmitButton}
        onClick={() => confirm()}
      >
        {messages.invoice}
      </div>
      <div
        role="button"
        tabIndex={0}
        className={styles.ToCorrect}
        onClick={() => close()}
      >
        {messages.returnData}
      </div>
    </div>
  </div>
)

export default Modal
