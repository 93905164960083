const messages = {
  title: 'Verifica tus datos',
  subTitle: 'Revisa que tus datos sean correctos ya que se emitirá un comprobante con validez fiscal.',
  cfdi: 'Uso de CFDI',
  ticket: 'No. de ticket',
  socialReasons: 'Razón social',
  rfc: 'RFC',
  invoice: 'FACTURAR',
  returnData: 'Corregir datos'
}

export default messages
