import React from 'react'
import styles from './Success.module.css'
import messages from './Success.messages'

/**
 * Success Component
 * @param {Object} response - json to the response
 * @return {void}
 */
class Success extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  /**
   * @returns {void}
   */
  render() {
    const position = 0
    window.scrollTo(position, position)

    const { response } = this.props
    const { email, pdf, xml } = response
    const urlPdf = pdf && pdf.url
    const urlXML = xml && xml.url
    const description = email ? messages.description.replace('{email}', email) : ''

    return (
      <div className={styles.SuccessContainer}>
        <div className={styles.SuccessTitle}>{messages.title}</div>
        <div className={styles.Description}>{description}</div>
        <div className={styles.FormSubmitButton}>
          <div
            role="button"
            tabIndex={0}
            className={styles.SubmitButton}
            onClick={() => { window.open(urlPdf, '_blank') }}
          >
            {messages.downloadPdf}
          </div>
          <div
            role="button"
            tabIndex={0}
            className={styles.SubmitButton}
            onClick={() => { window.open(urlXML, '_blank') }}
          >
            {messages.downloadXML}
          </div>
        </div>
      </div>
    )
  }
}

export default Success
